'use strict';

import http from '@/utils/http';

export default {
  // 议程分页
  classifyUrl: '/agenda-topics/page',

  // 新增日程
  add(code, data) {
    return http.post('/agenda-topics', data, { code });
  },
  // 编辑日程
  update(code, data) {
    return http.put('/agenda-topics', data, { code });
  },
  // 删除日程
  delete(code, params) {
    return http.delete(
      `/agenda-topics?id=${params.id}&scheduleId=${params.scheduleId}`,
      { code }
    );
  },
  // 查询日程数据表
  getdetail(code, id) {
    return http.get(`/agenda-topics/${id}`, { code });
  },
  //  查询演讲嘉宾下拉列表
  getAgendaTree(code, params) {
    return http.get('/guest', {
      code,
      params,
    });
  },
};
