<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <!--   todo 取值联调接口时需要更改   -->
      <!--   活动类型 -- 专题类型 -- 日期 -- 专题标签   -->
      <div class="agenda">
        {{ level.activityTypeName }}>{{ level.activity }}>{{
          level.topicLabelName
        }}>{{ level.topicTypeName }}
      </div>
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :url-params="{ scheduleId: agendaId }"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #language>
          <span>{{ viewAgenda.language }}</span>
        </template>
        <template #guestAgendaVoList="{ record }">
          <span v-for="item in record.guestAgendaVoList" :key="item.id"
            >{{ item.guestName }},</span
          >
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
    </div>
  </div>

  <iss-angenda-edit
    v-model:visible="visible"
    :init-value="activeItemView"
    @fnOk="handleFnOkByEdit"
  />
  <iss-angenda-view
    v-model:visible="visibleView"
    :init-value="activeItemView"
    @fnOk="handleFnOkByView"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { Tag, message } from 'ant-design-vue';
// import { useRouter } from 'vue-router';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import issAngendaEdit from './angendaEdit';
import issAngendaView from './angendaView';
import genderApi from '@/api/gender';
import classifyApi from '@/api/classify';
import { useRoute } from 'vue-router';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    issAngendaEdit,
    issAngendaView,
  },
  setup() {
    const route = useRoute();
    const agendaId = route.params.id;
    const gridRef = ref();
    const store = useStore();
    const viewAgenda = store.state.common.viewAgenda;
    const state = reactive({
      visible: false,
      visibleView: false,
      search: {},
      activeItem: {},
      activeItemView: {},

      level: {
        activityTypeName: '',
        activity: '',
        topicLabelName: '',
        topicTypeName: '',
      },
    });
    // const form = reactive({
    //
    // });

    classifyApi.getActivities('', agendaId).then(res => {
      console.log('res555', res);
      Object.assign(state.level, res);
    });

    return {
      // form,
      gridRef,
      agendaId,
      viewAgenda,
      ...toRefs(state),
      items: [{ key: 'themeName', label: '议程名称' }],
      columns: [
        {
          dataIndex: 'language',
          title: '语言',
          width: 120,
          slots: { customRender: 'language' },
          ellipsis: true,
        },
        {
          dataIndex: 'themeName',
          title: '议程名称',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'themeTime',
          title: '议程时间',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'guestAgendaVoList',
          title: '演讲嘉宾',
          width: 100,
          slots: { customRender: 'guestAgendaVoList' },
          ellipsis: true,
        },

        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'classify:add',
          fnClick: () => {
            state.visible = true;
            state.activeItemView = {};
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'agenda:update',
          fnClick: record => {
            state.visible = true;
            const id = record.id;
            genderApi.getdetail('', id).then(data => {
              console.log('res', data);
              state.activeItemView = { ...data };
            });
          },
        },
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'contact:view',
          fnClick: record => {
            // 点击查看时传一个查看的状态去弹窗，然后弹窗判断该状态是true，则disble input
            state.visibleView = true;
            const id = record.id;
            genderApi.getdetail('', id).then(res => {
              state.activeItemView = { ...res, disabled: true };
            });
          },
        },

        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) => {
            let parmas = {
              id: id,
              scheduleId: agendaId,
            };
            genderApi.delete('resources:delete', parmas).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      url: genderApi.classifyUrl,
      gridHeight: document.body.clientHeight - 386,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        const { id } = value;
        id && (type = 'update');

        genderApi[type](`stand:${type}`, value).then(() => {
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
          // setTimeout(() => {
          // }, 1500);
        });
        // 保存失败后 恢复保存按钮
        // setTimeout(() => {
        // }, 1500);
      },
      // 查看
      handleFnOkByView: () => {},
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.agenda {
  margin-bottom: 10px;
}
</style>
