<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑日程' : '新增日程'"
    :mask-closable="false"
    :width="680"
    class="model"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      ref="formRef"
      :model="form"
    >
      <a-form-item label="语言" v-bind="validateInfos.language">
        <!--        <a-input v-model:value="form.language" disabled />-->
        <a-radio-group
          v-model:value="form.language"
          :options="languageList"
          disabled
        />
      </a-form-item>
      <a-form-item label="议程名称" v-bind="validateInfos.themeName">
        <a-input v-model:value="form.themeName" placeholder="请输入议程名称" />
      </a-form-item>
      <a-form-item label="议程开始时间" v-bind="validateInfos.startTime">
        <a-date-picker
          v-model:value="form.startTime"
          show-time
          type="date"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择议程开始时间"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="议程结束时间" v-bind="validateInfos.endTime">
        <a-date-picker
          v-model:value="form.endTime"
          show-time
          type="date"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择议程结束时间"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="演讲嘉宾" v-bind="validateInfos.speakersName">
        <a-select
          v-model:value="form.speakersName"
          placeholder="请选择演讲嘉宾"
          mode="multiple"
          :filter-option="
            (inputValue, option) => filterOption(inputValue, option)
          "
        >
          <a-select-option
            v-for="item in agendaList"
            :key="item.id"
            :value="item.id"
            :label="item.guestName"
            :disabled="
              form.speakersName.length >= 5 &&
              !form.speakersName?.includes(item.id)
            "
          >
            {{ item.guestName }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import {
  reactive,
  toRaw,
  toRefs,
  watch,
  ref,
  onBeforeUnmount,
  onMounted,
} from 'vue';
import { Form, Select, DatePicker, Radio } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import classifyApi from '@/api/classify';
import genderApi from '@/api/gender';
import moment from 'moment';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADatePicker: DatePicker,
    ARadioGroup: Radio.Group,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const route = useRoute();
    const editorRef = ref();
    const formRef = ref();
    const required = { required: true, message: '不能为空' };
    const agendaId = route.params.id;
    const form = reactive({
      scheduleId: agendaId,
      id: '',
      language: '',
      endTime: null,
      startTime: null,
      speakersName: [],
      themeName: '',
    });
    const state = reactive({
      agreementList: [],
      agreementDemList: [],
      agendaList: [],
      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
    });
    // resetFields,
    const { validateInfos, resetFields } = Form.useForm(form, {
      startTime: [required],
      endTime: [required],
      language: [required],
      speakersName: [required],
      themeName: [required, { max: 200, message: '议程名称不能超过200个字符' }],
    });

    watch(
      () => props.initValue,
      initValue => {
        initValue.startTime = moment(initValue.startTime)
          .format('YYYY-MM-DD HH:mm:ss')
          .toString();
        initValue.endTime = moment(initValue.endTime)
          .format('YYYY-MM-DD HH:mm:ss')
          .toString();
        console.log('initValue', initValue);
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
          getActivitiesData();
        }
      }
    );

    genderApi.getAgendaTree('', '').then(data => {
      state.agendaList = data;
    });

    onMounted(() => {
      getActivitiesData();
    });
    const getActivitiesData = () => {
      // 根据日程id查看单条数据语言
      classifyApi.getActivities('', agendaId).then(res => {
        form.language = res.language;
      });
    };
    const handleOk = () => {
      form.startTime = moment(form.startTime)
        .format('YYYY-MM-DDTHH:mm:ss')
        .toString();
      form.endTime = moment(form.endTime)
        .format('YYYY-MM-DDTHH:mm:ss')
        .toString();
      if (form.disabled) {
        form.disabled = false;
        context.emit('update:visible', false);
      } else {
        form.disabled = false;
        formRef.value
          .validate()
          .then(() => {
            context.emit('fnOk', toRaw(form));
          })
          .catch(res => {
            console.log(res);
            if (res.errorFields.length == 0) {
              handleOk();
            }
          });
      }
    };

    onBeforeUnmount(() => {
      editorRef.value && editorRef?.value.remove();
    });

    return {
      ...toRefs(state),
      form,
      validateInfos,
      formRef,
      getActivitiesData,
      filterOption: (input, option) => {
        return option.children[0].children.indexOf(input) >= 0;
      },

      handleCancel: () => {
        form.disabled = false;
        context.emit('update:visible', false);
      },

      handleOk,
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
</style>
