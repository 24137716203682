<template>
  <a-modal
    :visible="visibleView"
    :title="'查看日程'"
    :mask-closable="false"
    :width="680"
    class="model"
    @ok="handleOk"
    style="top: 20px"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="语言">
        <!--        <a-input v-model:value="form.language" disabled />-->
        <a-radio-group
          v-model:value="form.language"
          :options="languageList"
          disabled
        />
      </a-form-item>
      <a-form-item label="议程名称">
        <a-input
          v-model:value="form.themeName"
          :disabled="true"
          placeholder="请输入议程名称"
        />
      </a-form-item>
      <a-form-item label="开始时间">
        <a-date-picker
          v-model:value="form.startTime"
          show-time
          type="date"
          placeholder="选择时间"
          style="width: 100%"
          :disabled="true"
        />
      </a-form-item>
      <a-form-item label="结束时间">
        <a-date-picker
          v-model:value="form.endTime"
          show-time
          type="date"
          placeholder="选择时间"
          style="width: 100%"
          :disabled="true"
        />
      </a-form-item>
      <!--    循环遍历演讲嘉宾和嘉宾描述   -->
      <div v-for="item in form.guestAgendaVoList" :key="item.id">
        <a-form-item label="演讲嘉宾">
          <!--         -->
          <a-input v-model:value="item.guestName" placeholder="请输入演讲嘉宾">
          </a-input>
        </a-form-item>
        <a-form-item label="嘉宾简介">
          <a-textarea
            v-model:value="item.guestInformation"
            :disabled="true"
            placeholder="请输入嘉宾简介"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRefs, watch, ref, onBeforeUnmount } from 'vue';
import { Form, DatePicker, Radio } from 'ant-design-vue';
import classifyApi from '@/api/classify';
import { useRoute } from 'vue-router';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ADatePicker: DatePicker,
    ARadioGroup: Radio.Group,
  },
  props: {
    visibleView: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const route = useRoute();
    const agendaId = route.params.id;
    const editorRef = ref();
    const form = reactive({
      id: '',
      agendaName: '',
      content: '',
      endTime: null,
      startTime: null,
      guestInformation: '',
      speakersName: [],
      language: null,
      themeName: '',
      guestAgendaVoList: [],
    });
    const state = reactive({
      agreementList: [],
      agreementDemList: [],
      auditStatusList: [
        { value: 'unaudited', label: '未审核', id: '12' },
        { value: 'approve', label: '审核通过', id: '45' },
        { value: 'reject', label: '审核不通过', id: '678' },
      ],
      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
    });

    watch(
      () => props.initValue,
      initValue => {
        Object.assign(form, initValue);
      }
    );

    // 根据日程id查看单条数据语言
    classifyApi.getActivities('', agendaId).then(res => {
      form.language = res.language;
    });

    onBeforeUnmount(() => {
      editorRef.value && editorRef?.value.remove();
    });

    return {
      ...toRefs(state),
      form,
      //下拉框多选 todo 最多只能选10个演讲嘉宾
      handleChange: value => {
        console.log('value多选', value);
      },
      filterOption: (input, option) => {
        return option.children[0].children.indexOf(input) >= 0;
      },

      handleCancel: () => {
        form.disabled = false;
        context.emit('update:visible', false);
      },
      handleOk: () => {
        context.emit('update:visible', false);
      },
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
</style>
